import { Outlet } from 'react-router-dom';
import Nav from '../Nav/Nav';
import './App.css';

function Main() {
  return (
    <div className="app">

      <div className='introduction'>
        <p>
          <span className='hey-text'>Hey!</span><br /><br />
          I am <span className='name-text'>Alexander Hadula</span><br /> <i>creative developer from Lower Saxony, Germany.</i>
        </p>
      </div>
      <div className='nav'>
        <Nav />
        <p className='contact'>Contact me</p>
        <p><a href="https://www.linkedin.com/in/alexander-hadula-67a25a242/">LinkedIn</a><br/><a href="mailto:alexander.hadula@gmail.com">Mail</a><br/><a href="https://github.com/NightlyDev">Github</a></p>
      </div>
      <div className='transparencyscreen'>
      </div>
      <video muted autoPlay loop id='video'>
        <source src={require('../bin/video/background.mp4')} type="video/mp4" />
      </video>
      <div className='router'>
        <Outlet>
        </Outlet>
      </div>
    </div>
  );
}

export default Main;