import { Link } from 'react-router-dom';
import './aboutme.css';

function Aboutme() {
    return (
        <div className='aboutme'>
            <div>
                <h1>About me</h1>
                <hr />
                <p>
                    Hey! I am Alexander Hadula - Creative Developer that tries to discover new, exciting topics that inspire me to create new things.
                    As you might can tell from my <Link to='/gallery'>gallery</Link> and <Link to='/music'>music projects</Link>, I am interested in all kinds of topics. <br /><br />
                    In the creative department, I produce music, edit videos and I am also currently developing my own prototype videogame with Unity,
                    where I try to put all my skills to the test to create something fun!

                    Check out the <Link to='/gallery'>gallery</Link> or <Link to='/music'>music projects</Link> for a sneak peek.<br /> <br />

                    There are also a handful programming related topics like design patterns, web applications, microservices, software architectures and the rust programming language I am currently interested in.
                </p>
            </div>
            <div>
                <h1>School</h1>
                <hr />
                <table>
                    <thead>
                        <td>2015 - 2018</td>
                        <td>2018 - 2019</td>
                        <td>2019 - 2023</td>
                    </thead>
                    <tbody>
                        <td>
                            <tr>Apprenticeship at MMBBS [School] in Hannover</tr>
                        </td>
                        <td>
                            <tr>Technical College in Lüneburg</tr>
                        </td>
                        <td>
                            <tr>Bachelor of Science - Applied Computer Science</tr>
                        </td>
                    </tbody>
                </table>
                <h1>Work Experience</h1>
                <hr />
                <table>
                    <thead>
                        <td>2015 - 2018</td>
                        <td>2020 - 2023</td>
                        <td>Now</td>
                    </thead>
                    <tbody>
                        <td>
                            <tr>Apprenticeship at IT-Dienste360 Gmbh [Work] in Hannover</tr>
                        </td>
                        <td>
                            <tr>Fullstack Developer at Hochschule Hannover</tr>
                        </td>
                        <td>
                            <tr>Developer at DB Systel</tr>
                        </td>
                    </tbody>
                </table>
            </div>
            <div>
                <h1>Skills</h1>
                <hr />
                <div className='skills'>
                    <div>
                        <h2>Programming Languages</h2>
                        <ul>
                            <li>Javascript</li>
                            <li>Python</li>
                            <li>Java</li>
                            <li>C#</li>
                            <li>C++</li>
                        </ul>
                    </div>
                    <div>
                        <h2>Frameworks and Tools</h2>
                        <table>
                            <td>
                                <tr>
                                    <ul>
                                        <li>Django</li>
                                        <li>React.js</li>
                                        <li>Electron</li>
                                        <li>Unity</li>
                                        <li>Git & Gitlab</li>
                                    </ul>
                                </tr>
                            </td>
                            <td>
                                <ul>
                                    <li>PostgreSQL</li>
                                    <li>Visual Studio Code</li>
                                    <li>Ableton Live</li>
                                    <li>FL Studio</li>
                                    <li>Adobe Premiere Pro & After Effects</li>
                                    <li>Blender</li>
                                </ul>
                            </td>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutme;