import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import App from './App/App';
import Aboutme from "./pages/Aboutme";
import Gallery from "./pages/gallery/gallery";
import Music from "./pages/music/Music";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        path: "aboutme",
        element: <Aboutme />
      },
      {
        path: "gallery",
        element: <Gallery/>
      },
      {
        path: "music",
        element: <Music/>
      },
      {
        path: "news",
        element: <Aboutme/>
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  
);