import './gallery.css';

function Gallery() {
    return (
        <div className='gallery'>

            <div className='item'>
                <img src={require('../../bin/images/Illustration8.png')} alt="Painting of a girl with red hair and a green jacket staring into the darkness"></img>
                <p>Painting made in Clip Studio with techniques from <a href="https://youtube.com/watch?v=iwRa5qTnr8o&si=EnSIkaIECMiOmarE">Sinix Design</a></p>
            </div>
            <div className='item'>
                <video muted autoPlay loop>
                    <source src={require('../../bin/video/testgrass.mp4')} type="video/mp4" />
                </video>
                <p>A concept for stylized grass made in Blender. Will be recreated in Unitys own Shader Editor later on. The instances of grasblades and their movement were made with geometry nodes </p>
            </div>
            <div className='item'>
                <img src={require('../../bin/images/radar.gif')} alt="An animation of a radar with red dots moving through it"></img>
                <p>Shader programmed in GLSL. Depicts a radar for a minimap, with enemies running around. This is my first shader which I did with the help of <a href="https://thebookofshaders.com/">Book of Shaders</a>. Check out the code at <a href="https://www.shadertoy.com/view/DsfczH">Shader Toy!</a></p>

            </div>
            <div className='item'>
                <iframe width="500" height="500" src="https://www.youtube.com/embed/6ytYOY1O5hQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p>
                    Showcasevideo for the 'UnSensored - Crowdsensing-Ecosystem' bachelorproject. Created in Adobe Premiere Pro and After Effects by me.
                </p>
            </div>
            <div className='item'>
                <iframe src="https://www.youtube.com/embed/v_NsjpmdS9g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p>
                    Showcase video for the 'Spaß mit den Bois Discord Bot' Project. Created in Adobe Premiere Pro and After Effects by me.
                </p>
            </div>
            <div className='item'>
                <video width="500" height="500" controls>
                    <source src={require('../../bin/video/Kiggle.mp4')} type="video/mp4" />
                </video>
                <p>
                    Isometric pixelart videogame prototype created with Unity. Includes a vertex water shader, a particle system and Unitys URP 2D Lighting. Art and music made by me.
                </p>
            </div>
        </div >
    )
}

export default Gallery;