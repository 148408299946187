import NavButton from "./NavButton";

function Nav() {
    return (
        <div>
            <div >
                <NavButton text='Home' link='' />
            </div>
            <div >
                <NavButton text='About me' link='aboutme' />
            </div>
            <div >
                <NavButton text='Gallery' link='gallery' />
            </div>
            <div >
                <NavButton text='Music Projects' link='music' />
            </div >
            <p>This website is still in development! 😿</p>
        </div>
    );
}

export default Nav;