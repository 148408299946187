import './music.css';

function Music(props) {
    return (

        <div className="music">
            <div className='item1 musicitem'>
                <div className='tablewrapper'>
                    <audio controls>
                        <source src={require('../../bin/music/battlefield_fanmade.wav')} type='audio/wav' />
                        Your browser does not support the audio element.
                    </audio>
                    <div className='tablecell'>
                        <span className='musictitle'>Battlefield Fanmade Soundtrack - Avalanche</span></div>
                </div>
                <div>
                    <p>
                        A fanmade soundtrack inspired by Battlefield 2042.
                        High-pitched synths and distorted bass were used to depict the gloomy future DICE created for their game.
                        Because of the colder nature of this track, the track was called "Avalanche".
                    </p>
                </div>
            </div>

            <div className='item2 musicitem'>
                <div className='tablewrapper'>
                    <audio controls>
                        <source src={require('../../bin/music/automation_test.mp3')} type='audio/mpeg' />
                        Your browser does not support the audio element.
                    </audio>
                    <div className='tablecell'>
                        <span className='musictitle'>Project Automation</span>
                    </div>
                </div>
                <div>
                    <p>
                        A music project focused on cold synths and automation.
                        Varying arpeggios, experimental sounds and the future bass direction brought out the best of this project and helped me to understand automation within Ableton.
                    </p>
                </div>
            </div>

            <div className='item3 musicitem'>
                <div className='tablewrapper'>
                    <audio controls>
                        <source src={require('../../bin/music/hyperpop.mp3')} type='audio/mpeg' />
                        Your browser does not support the audio element.
                    </audio>
                    <div className='tablecell'>
                        <span className='musictitle'>Project HyperPop</span>
                    </div>
                </div>
                <div>
                    <p>
                        As hyperpop started to rise in popularity, I wanted to capture this genre by myself.
                        Sounds and leads made from a step sequencer give this track an texture while auto-tune heavy vocals give this track a strong pop sound. 
                        The lyrics "Texting every night" were inspired by a story from a friend of mine, who is texting every night with a new friend from abroad. <br/>
                        Mind that this project is still unfinished, hence why the lyrics aren't completed.
                    </p>
                </div>
            </div>

            <div className='item4 musicitem'>
                <div className='tablewrapper'>
                    <audio controls>
                        <source src={require('../../bin/music/popsound.mp3')} type='audio/mpeg' />
                        Your browser does not support the audio element.
                    </audio>
                    <div className='tablecell'>
                        <span className='musictitle'>Project Vocals</span>
                    </div>
                </div>
                <div>
                    <p>
                        Project Vocals focused on pitching vocal samples, while also having a focus on pop sound. I tried to somewhat recreate a Chet Porter sound here.
                    </p>
                </div>
            </div>

            <div className='item5 musicitem'>
                <div className='tablewrapper'>
                    <audio controls>
                        <source src={require('../../bin/music/porterrobinson.mp3')} type='audio/mpeg' />
                        Your browser does not support the audio element.
                    </audio>
                    <div className='tablecell'>
                        <span className='musictitle'>Project Nurture</span>
                    </div>
                </div>

                <div>
                    <p>
                        Porter Robinson is my favorite musician in recent times.
                        His album Nurture had a very hugging and friendly sound, while also being very experimental with different kind of samples.
                        In one of his secret sky live sessions, he used the Amen Break sample to improvise on one of his tracks.
                        With the rising popularity of breakcore and the used sample by Porter Robinson, this track is a homage to his live session and sound.
                        Additionally, I tried to reproduce the higher pitched vocals Porter Robinson uses for his tracks.
                        The result is a very own spin of Porters sound.
                    </p>
                </div>
            </div>
        </div >
    )
}

export default Music;