import './NavButton.css';
import { Outlet, Link } from 'react-router-dom';

function NavButton(props) {
    return (
        
        <div className='navbutton'>
            <Link to={props.link}>{props.text}</Link>
        </div>
    )
}

export default NavButton;